<template>
  <v-card class="pa-8">
    <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
      <h3>เพิ่มสินค้า</h3>
      <v-row>
        <!-- <v-col cols="6"
          ><span>เลือกหมวดหมู่ของสินค้า*</span>
          <v-select
            v-model="category"
            :items="itemsCategory"
            item-text="category"
            item-value="id"
            label="เลือกหมวดหมู่สินค้า"
            outlined
            dense
            :rules="rules.required"
          ></v-select
        ></v-col> -->
        <v-col cols="12" md="3" sm="6"
          ><p class="pt-2">รหัสสินค้า</p>
          <v-text-field
            v-model="productNumber"
            label="รหัสสินค้า"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="9" sm="12"
          ><p class="pt-2">ชื่อสินค้า</p>
          <v-text-field
            v-model="productName"
            label="ชื่อสินค้า"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="5" sm="12"
          ><p class="pt-2">รายละเอียดสินค้า</p>
          <v-textarea
            v-model="description"
            label="รายละเอียดสินค้า"
            outlined
            dense
            rows="10"
            disabled
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="3" sm="6"
          ><p class="pt-2">ราคา</p>
          <v-text-field
            v-model="rentPrice"
            label="ราคา"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="6">
          <p class="pt-2">จังหวัด</p>
          <v-text-field
            v-model="province"
            label="จังหวัด"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-card outlined class="pa-4">
            <span>ข้อมูลการติดต่อ</span>
            <v-row align="center">
              <v-col cols="12" md="3" sm="12" xs="12"
                ><p class="pt-2">username</p>
                <v-text-field
                  v-model="user.username"
                  label="username"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12"
                ><p class="pt-2">ชื่อ</p>
                <v-text-field
                  v-model="user.name"
                  label="ชื่อ"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12"
                ><p class="pt-2">หมายเลขโทรศัพท์</p>
                <v-text-field
                  v-model="user.tel"
                  label="หมายเลขโทรศัพท์"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="12" xs="12"
                ><p class="pt-2">Line</p>
                <v-text-field
                  v-model="lineId"
                  label="Line"
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" sm="6" xs="12">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="11" class="mt-5 ml-10" id="step-3">
                <h2>รูปสินค้าหลัก*</h2>
                <v-img :src="showImage" width="200px"></v-img>
                <v-text-field
                  v-model="picture_1"
                  @click="changePic1()"
                  :placeholder="imageName1"
                  prepend-icon="mdi-camera"
                  readonly
                  disabled
                >
                </v-text-field
                ><input
                  type="file"
                  ref="image"
                  id="picTure1"
                  accept="image/*"
                  @change="showPicture1($event)"
                  style="display: none"
              /></v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" class="mt-5">
          <v-card outlined>
            <v-row no-gutters align="center">
              <v-col cols="11" class="mt-5 ml-10" id="step-3">
                <h2>รูปภาพสินค้าเพิ่มเติม</h2>
              </v-col>
              <v-col cols="12" class="mb-5"><v-divider></v-divider></v-col>
              <v-col cols="12" md="12" class="pl-3 mb-5" @click="onPickFile()">
                <v-row no-gutters align="center" justify="center">
                  <v-file-input
                    v-model="DataImage"
                    :items="DataImage"
                    accept="image/jpeg, image/jpg, image/png"
                    @change="UploadImage()"
                    id="file_input"
                    multiple
                    :clearable="false"
                    style="display: none"
                  ></v-file-input>
                  <!-- <v-img
                    max-width="50"
                    src="@/assets/upload.png"
                    class="mr-3"
                  ></v-img>
                  <span>เลือกรูปภาพ</span> -->
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="product_image.length !== 0"
                class="mb-5"
              >
                <draggable
                  v-model="product_image"
                  :move="onMove"
                  @start="drag = true"
                  @end="drag = false"
                  class="pl-5 pr-5 row fill-height align-center sortable-list"
                >
                  <v-col
                    v-for="(item, index) in product_image"
                    :key="index"
                    cols="12"
                    md="3"
                  >
                    <v-card dense light class="pa-1">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- <v-icon small light @click="RemoveImage(index, item)"
                          >mdi-close</v-icon
                        > -->
                      </v-card-actions>

                      <v-img
                        :src="item.productImgUrl"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                      <v-card-text class="text-md-center"> </v-card-text>
                    </v-card>
                  </v-col>
                </draggable>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <h2 class="pt-2">อนุมัติให้แสดงรายการ</h2>
          <v-select
            v-model="activeFlag"
            label="อนุมัติให้แสดงรายการ"
            :items="itemsactiveFlag"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6" sm="6">
          <h2 class="pt-2">แสดงที่ Sponsored Ad หรือไม่?</h2>
          <v-select
            v-model="isSponsor"
            label="แสดงที่ Sponsored Ad"
            :items="itemsSponsors"
            item-text="text"
            item-value="value"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="ma-5">
        <v-btn @click="cancel()">กลับ</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="submit()" color="primary">บันทึก</v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>
<script>
import { Decode } from "@/services";
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      id: "",
      // mainImg: "",
      productImgs: [],
      productType: "",
      productNumber: "",
      productName: "",
      description: "",
      lineId: "",
      rentPrice: "",
      province: "",
      img: [],
      DataImage: [],
      product_image: [],
      pic1: "", //mainImg
      picture_1: "",
      showImage: "",
      imageName1: "",
      user: {
        username: "",
        name: "",
        tel: "",
      },
      activeFlag: false,
      itemsactiveFlag: [
        { text: "ยังไม่อนุมัติ", value: false },
        { text: "อนุมัติ", value: true },
        // { text: "ตีกลับ", value: "2" },
      ],
      isSponsor: false,
      itemsSponsors: [
        { text: "ไม่แสดง", value: false },
        { text: "แสดง", value: true },
        // { text: "ตีกลับ", value: "2" },
      ],
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
    };
  },
  async created() {
    var productdata = JSON.parse(
      Decode.decode(localStorage.getItem("productdata"))
    );
    console.log("productdata", productdata);
    this.id = productdata.id;
    this.getOne();
    // this.itemsCategory = cateDropdown.data.data;
    // this.itemsType = typeDropdown.data.data;
    // console.log(this.itemsCategory, this.itemsType);
  },
  methods: {
    async getOne() {
      // const cateDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/categories`
      // );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      // this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      // console.log(this.itemsCategory, this.itemsType);
      ////////////get dropdown list/////////////
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/` + this.id
      );
      // id: "",
      // // mainImg: "",
      // productImgs: [],
      // productType: "",
      // productNumber: "",
      // productName: "",
      // description: "",
      // lineId: "",
      // rentPrice: "",
      // province: "",
      // img: [],
      // DataImage: [],
      // // product_image: [],
      // pic1: "", //mainImg
      // picture_1: "",
      // showImage: "",
      // imageName1: "",
      // user: {
      //   username: "",
      //   name: "",
      //   tel: "",
      // },
      console.log("Product", response);
      const Product = response.data.data;
      this.activeFlag = Product.activeFlag;
      this.productType = Product.productType;
      this.productNumber = Product.productNumber;
      this.productName = Product.productName;
      this.description = Product.description;
      this.lineId = Product.lineId;
      this.rentPrice = Product.rentPrice;
      this.province = Product.province;
      this.user = Product.user;
      this.product_image = Product.productImgs;
      this.showImage = Product.mainImg;
      this.picture_1 = Product.mainImg;
      // this.activeFlag = Product.activeFlag;
      this.isSponsor = Product.isSponsor;
    },
    // UploadImage() {
    //   for (let i = 0; i < this.DataImage.length; i++) {
    //     const element = this.DataImage[i];
    //     const reader = new FileReader();
    //     reader.readAsDataURL(element);
    //     reader.onload = () => {
    //       var resultReader = reader.result;
    //       var url = URL.createObjectURL(element);
    //       this.product_image.push({
    //         image_data: resultReader,
    //         url: url,
    //         name: this.DataImage[i].name,
    //         id: "-1",
    //       });
    //     };
    //   }
    // },
    //  onPickFile() {
    //   document.getElementById("file_input").click();
    // },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext;
      const draggedElement = draggedContext;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      );
    },
    RemoveImage(index, item) {
      console.log(index, item);
      if (item.id !== "-1") {
        this.Remove_img.push({
          id: item.id,
        });
      }
      this.product_image.splice(index, 1);
    },
    cancel() {
      this.$router.push("ManageProduct");
    },
    async submit() {
      if (this.$refs.createForm.validate(true)) {
        const productData = {
          approveFlag: this.activeFlag,
          isSponsor: this.isSponsor
        };
        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userForrent"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${user.token}` },
        };
        console.log("data", productData);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/products/approveProduct/` + this.id,
          productData,
          auth
        );
        console.log("response product", response);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `แก้ไขสินค้าสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageProduct");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขสินค้าไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>